<!--
 * @Author: codeshitmeshit
 * @Date: 2023-07-05 20:18:27
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-07-17 02:48:15
 * @FilePath: \webapp\src\webapp\Mode\PageAside.vue
 * @Description: 
 * codeshitmeshit
-->
<template>
    <div>
        <el-aside width="250px" style="background-color: transparent; default-active: 0; height: 100%" >
            <el-menu :default-openeds="['1']" :unique-opened="true" style="background-color: transparent;">
                <el-menu-item index="0-1">
                  <router-link to="/Trend/BaiduHot" tag="el-button"><i class="el-icon-watermelon"></i><font style="color: red; font-size: medium;">百度热搜</font></router-link>
                </el-menu-item>
                <el-menu-item index="0-2">
                  <router-link to="/Trend/WeiboHot" tag="el-button"><i class="el-icon-toilet-paper"></i><font style="color: red; font-size: medium;">微博热搜</font></router-link>
                </el-menu-item>
                <el-menu-item index="0-3">
                    <router-link to="/Trend/BilibiliTop" tag="el-button"><i class="el-icon-video-play"></i><font style="color: red; font-size: medium;">b站排行</font></router-link>
                  </el-menu-item>

              <!-- 书签栏
              <el-menu-item index="0-0">
                    <router-link to="/Trend/WeiboHot" tag="el-button"><i class="el-icon-collection"></i><font style="color: black; font-size: medium;">书签栏</font></router-link>
              </el-menu-item> -->
              <!-- 查询 -->
              <el-menu-item index="1-1">
                      <router-link to="/search/ComprehensiveQuery" tag="el-button"><i class="el-icon-search"></i><font style="color: black; font-size: medium;">查询</font></router-link>
              </el-menu-item>
              <!-- 查看历史记录类菜单 -->

              <el-submenu index="2">
                <template slot="title"><i class="el-icon-time"></i><font style="color: black; font-size: medium;">历史</font></template>
                <el-menu-item-group>
                    <el-menu-item index="2-1">
                      <router-link to="/history/SearchHistory" tag="el-button">* 搜索历史</router-link>
                    </el-menu-item>
                    <!-- <el-menu-item index="2-2">
                      <router-link to="/history/SearchData" tag="el-button">* 搜索数据</router-link>
                    </el-menu-item> -->
                    <el-menu-item index="2-3">
                      <router-link to="/history/OperateLog" tag="el-button">* 操作日志</router-link>
                    </el-menu-item>
                </el-menu-item-group>
              </el-submenu>
            
              <!-- 查看储存信息类菜单 -->

              <el-submenu index="3">
                <template slot="title"><i class="el-icon-coin"></i><font style="color: black; font-size: medium;">数据</font></template>
                <el-menu-item-group>
                    <el-menu-item index="3-1">
                      <router-link to="/data/UserData" tag="el-button">* 用户数据</router-link>
                    </el-menu-item>
                    <el-menu-item index="3-2">
                      <router-link to="/data/AddData" tag="el-button">* 添加数据</router-link>
                    </el-menu-item>
                    <el-menu-item index="3-3">
                      <router-link to="/data/DataQuery" tag="el-button">* 查询数据</router-link>
                    </el-menu-item>
                </el-menu-item-group>
              </el-submenu>

              <!-- 快速生成md图片类菜单 -->
              <el-submenu index="4">
                <template slot="title"><i class="el-icon-picture-outline-round"></i><font style="color: black; font-size: medium;">图片</font></template>
                <el-menu-item-group>
                  <el-menu-item index="4-1">
                    <router-link to="/Pic/PicUpload" tag="el-button">* 上传图片</router-link>
                  </el-menu-item>
                  <el-menu-item index="4-2">
                    <router-link to="/Pic/PicHistory" tag="el-button">* 图片管理</router-link>
                  </el-menu-item>
                </el-menu-item-group>
              </el-submenu>
            </el-menu>
          </el-aside>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
.el-aside {
  width: 350;
  text-align: center;
  background-color: transparent;
}

.el-button {
  border: 0;
  background-color: transparent;
}

</style>